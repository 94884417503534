<template>
  <div>
    <van-nav-bar title="选择登录方式" />
    <div class="content">
      <div v-if="!hasCode">
        <van-button block round type="primary" @click="toPage('auth')">
          授权登录
        </van-button>
      </div>
      <div class="line" v-if="!hasCode">
        <div class="dot"></div>
      </div>
      <div>
        <van-button block round plain type="primary" @click="toPage('login')">
          账号登录
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage.js";
import { authUrl } from "@/config/const.js";
import { wechatAuth } from "@/apis/auth.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      hasCode: false,
      code: "",
      res: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let userInfo = storage.get("loginUserInfo");
      if (userInfo && userInfo.accessToken) {
        this.$router.push({
          path: "/list",
        });
      }

      let href = window.location.href;
      let query = this.getUrlkey(href.split("#")[0]);
      this.code = query.code;

      //code存在说明官方授权成功
      if (this.code) {
        let toast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });

        this.hasCode = true;
        //判断是否有账号绑定了微信
        wechatAuth(this.code).then((res) => {
          this.res = res;
          toast.clear();
          if (res.succeeded) {
            storage.set("loginUserInfo", res.data);
            let bind = res.data.bind;
            //绑定了就直接进入页面，么有就去绑定页面
            if (bind) {
              this.$router.replace({
                path: "/list",
              });
            } else {
              Toast("当前微信号未绑定系统！请进行绑定");
              setTimeout(() => {
                this.$router.replace({
                  path: "/bind",
                });
              }, 1500);
            }
          } else {
            window.open(authUrl, "_parent");
          }
        });
      } else {
        this.hasCode = false;
      }
    },
    toPage(key) {
      if (key === "auth") {
        window.open(authUrl, "_parent");
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // url参数解析
    getUrlkey(url) {
      var params = {};
      var urls = url.split("?");
      if (urls[1]) {
        var arr = urls[1].split("&");
        for (var i = 0, l = arr.length; i < l; i++) {
          var a = arr[i].split("=");
          params[a[0]] = a[1];
        }
      }
      return params;
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  padding: 40px 20px 0;
}
.line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
  position: relative;

  .dot {
    width: 30px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .dot:after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background-color: #ddd;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
